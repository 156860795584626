<template>

  <div  transition="fadein" class="fixed inset-0 z-10 overflow-y-auto transition-all duration-300 ease-in modal" ref="modal" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-center justify-center min-h-screen px-2 pt-4 pb-20 text-center sm:px-4 sm:block sm:p-0">
      <div class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" aria-hidden="true"></div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

      <div :class="artist_only || venue_only ? 'sm:max-w-md': 'sm:max-w-2xl'" 
      class="inline-block w-full px-6 pt-5 pb-4 mx-4 overflow-hidden text-left align-bottom transition-all transform rounded-lg shadow-xl bg-gp_navy-default-500 sm:px-4 sm:my-8 sm:align-middle sm:w-full sm:p-6">
    <div>
      <div class="flex flex-col items-center justify-between gap-4 mx-auto ">

        <img src="@/assets/images/icons/single_logo.svg" alt="GigPig" class="w-24" />
        <h3 class="text-2xl font-bold text-white" id="modal-title">
          GigPig
        </h3>
      </div>
      <div class="text-left sm:mt-5" v-if="!venue_only && !artist_only">
        <div class="mt-8 text-sm">
          <p class="mb-4" >
            Are you an artist?
          </p>
          <p class="mb-4">
            Pick an option below to login or register.
          </p>
        </div>
      </div> 
    </div>

    <ul role="list" class="grid grid-flow-row gap-6 lg:grid-flow-col">
      <li v-if="!artist_only" :class="allowVenues ? '' : 'deter_nav'"  class="flex flex-col col-span-1 text-center divide-y divide-gray-200 rounded-lg shadow bg-gp_navy-default-500">
        <div class="flex flex-col flex-1 ">
           <h3 class="mt-8 text-sm font-bold text-white uppercase">Venue Management</h3>



          <dl class="flex flex-col justify-between flex-grow mt-6">
            <dt class="sr-only">New Venue? Sign up</dt>
            <dd class="">
              <a rel="nofollow" @click="signupEvents($auth);" 
              class="block w-full py-2 mt-2 text-sm font-semibold text-center bg-white border border-gray-800 rounded-md text-gp_navy-default-500 hover:bg-gp_pink-default-500 hover:text-white">
                New Venue? Sign up
              </a>
            </dd>
          </dl>

          <dl class="flex flex-col justify-between flex-grow mt-4">
            <dt class="sr-only">Login</dt>
            <dd class="">
              <a rel="nofollow" @click="loginEvents($auth);" 
              class="block w-full py-2 mt-2 text-sm font-semibold text-center bg-white border border-gray-800 rounded-md text-gp_navy-default-500 hover:text-white hover:bg-gp_pink-default-500">
                Log in to an existing venue
              </a>
            </dd>
          </dl>


          <dl class="mt-4 external">
            <router-link class="text-xs text-white" to="/">Click here to switch to artist login</router-link>
          </dl>
        </div>
      </li>
      <li v-if="!venue_only" class="flex flex-col col-span-1 text-center divide-y divide-gray-200 rounded-lg shadow bg-gp_navy-default-500">
        <div class="flex flex-col flex-1">
 
          <h3 class="mt-8 text-sm font-bold text-white uppercase">Artist Area</h3>

          <dl class="flex flex-col justify-between flex-grow">
            <dt class="sr-only">Sign up</dt>
            <dd >
              <a href="#"  rel="nofollow" @click="signupArtists($auth);" class="block w-full py-2 mt-8 text-sm font-semibold text-center bg-white border border-gray-800 rounded-md text-gp_navy-default-500 hover:bg-gp_pink-default-500 hover:text-white">New artist? Sign up</a>
            </dd>
          </dl>
          <dl>
            <p class="mt-2 text-sm font-semibold text-white uppercase">Or</p>
          </dl>

          <dl class="flex flex-col justify-between flex-grow ">
            <dt class="sr-only">Login</dt>
            <dd class="">
              <a  rel="nofollow"  @click="loginArtists($auth);" class="block w-full py-2 mt-2 text-sm font-semibold text-center bg-white border border-gray-800 rounded-md text-gp_navy-default-500 hover:bg-gp_pink-default-500 hover:text-white">Log in to an existing account</a>
            </dd>
          </dl>

          <dl class="flex justify-center mt-8">
            <router-link class="text-xs text-white" to="/venues/login">Click here to switch to venue login</router-link>
          </dl>
        </div>
      </li>
    </ul>

  </div>

    </div>
  </div>


</template>

<script>
import branding from '@/global/branding'
export default {
  name: "GigPig",
  props:{
    artist_only: Boolean,
    venue_only: Boolean,
    allowVenues: {type: Boolean, default: true},
    alignment: {type: String, default: 'justify-center'}
  },
  methods: {
    signupArtists($auth){

      this.$global.switchRoute('artist');
      this.$global.signup($auth, branding.auth0Params({screen_hint: 'signup', allowLogin: false}));
    },
    loginArtists($auth){
      this.$global.switchRoute('artist');
      this.$global.login($auth, branding.auth0Params({allowSignUp: false}));
    },
    signupEvents($auth){

      this.$global.switchRoute('event');
      this.$global.signup($auth, branding.auth0Params({screen_hint: 'signup', allowLogin: false}));
    },
    loginEvents($auth){

      this.$global.switchRoute('event');
      this.$global.login($auth, branding.auth0Params({allowSignUp: false}));
    },
    clearSessionVariables() {

      localStorage.removeItem('pendingGigsEndDate');
      localStorage.removeItem('pendingGigsStartDate');
      localStorage.removeItem('pendingGigsStatuses');
      localStorage.removeItem('pendingGigsTagFilter');
      localStorage.removeItem('pendingGigsVenueFilter');
      localStorage.removeItem('pendingGigsVenues');
      localStorage.removeItem('CALENDAR_CURRENT_DATE');
      localStorage.removeItem('CURRENT_DATE');
      localStorage.removeItem('LOCK_VENUE_1');
      localStorage.removeItem('SHOW_ARTIST_NAME');
      localStorage.removeItem('WEEK_START_DAY');
    },
  },
  mounted() {
      this.clearSessionVariables();
  }
}
</script>

<style scoped>

.external .router-link-active {

  @apply bg-transparent;

}
.fadeIn-transition {
  transition: all .3s ease;
  opacity:0;
}
.expand-enter, .expand-leave {
  opacity: 1;
}
</style>