<template>
    <multiselect 
        v-model="selectedOptions" 
        :options="options"
        :searchable="searchable"
        :multiple="multiple"
        :taggable="taggable"
        :close-on-select="closeOnSelect"
        :show-labels="showLabels"
        @select="optionSelected"
        @remove="optionDeselected"
        :placeholder="placeHolder"
        :track-by="trackBy"
        label="name"
        :open-direction="openDirection"
        deselect-label=""
        >
        <template slot="caret">
        <div class="multiselect__select">
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="flex-shrink-0 w-4 h-4 ml-1 text-gray-600 multiselect_select_symbol"><path clip-rule="evenodd" fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"></path></svg>
        </div>
        </template>
        <template slot="tag" slot-scope="props">
            <span v-if="selectedOpt(props.option)">{{ displayText }}</span>
            <span v-else></span>
    </template>
</multiselect>
</template>


<script type="text/javascript">

    import Multiselect from 'vue-multiselect'

    export default {
        name: "MultiSelectFilter",
        components:
        {
            Multiselect
        },
        created() {
            this.selectedOptions = this.selectedOptionProps;
        },
        data() {
            return {
                selectedOptions: []
            }
        },
        props: {
            displayText: {
                type: String,
                required: true,
                default: ''
            },
            options: {
                type: Array,
                required: true
            },
            selectedOptionProps: {
                type: Array,
                required: true,
                default: () => []
            },
            searchable: {
                type: Boolean,
                default: false,
                required: false
            },
            multiple: {
                type: Boolean,
                default: true,
                required: false
            },
            taggable: {
                type: Boolean,
                default: false,
                required: false
            },
            closeOnSelect: {
                type: Boolean,
                default: false,
                required: false
            },
            showLabels: {
                type: Boolean,
                default: false,
                required: false
            },
            placeHolder: {
                type: String,
                default: 'Filter by...',
                required: false
            },
            trackBy: {
                type: String,
                default: 'id',
                required: false
            },
            openDirection: {
                type: String,
                default: 'bottom',
                required: false
            },
            deSelectLabel: {
                type: String,
                default: '',
                required: false
            }
        },
        watch: {
            selectedOptionProps: {
                handler(newVal) {
                    this.selectedOptions = newVal;
                },
                deep: true
            }
        },
        methods: {
            optionSelected(option) {
                this.$emit('optionSelected', option);
            },
            optionDeselected(option) {
                this.$emit('optionDeselected', option);
            },
            selectedOpt(option) {
                return this.selectedOptions[this.selectedOptions.length-1][this.trackBy] === option[this.trackBy];
            }
        },
        emits: ['optionSelected', 'optionDeselected']
    }
    
</script>



<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  color: #000;
  font-size: 15px;
  padding: 12px 40px 0 12px;
  min-height: 47px;
  border: none;
}
.multiselect {
  cursor: pointer;
  border-radius: 0.25rem;
  border-width: 1px;
  border-color: rgba(209, 213, 219);
}
.multiselect:hover {
  background-color: transparent;
}
.multiselect:hover > div > div {
  background-color: transparent;
}

.multiselect__select {
    top: 4px;
}
svg.multiselect_select_symbol {
  color: #555;
  position: relative;
  top: 6px;
}
.multiselect__select:before {
  display: none;
}
.multiselect__content-wrapper {
  border-radius: 0.25rem;
  border-width: 1px;
  border-color: rgba(209, 213, 219);
  border-top-right-radius: none;
  border-top-left-radius: none;
  border-top: none;
}
.multiselect__element:hover .multiselect__option,
.multiselect__element:hover .multiselect__option:after
{
  background-color: #f3f3f3;
  color: #000 !important;
}
.multiselect__element:hover .multiselect__option--selected,
.multiselect__element:hover .multiselect__option--selected:after
{
  background-color: rgb(241, 113, 144);
  color: #fff;
}
.multiselect__option--selected:after,.multiselect__option--selected.multiselect__option--highlight:after {

  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M19.8198 6.19526C20.0601 6.45561 20.0601 6.87772 19.8198 7.13807L9.9736 17.8047C9.73328 18.0651 9.34364 18.0651 9.10332 17.8047L4.18024 12.4714C3.93992 12.2111 3.93992 11.7889 4.18024 11.5286C4.42056 11.2682 4.8102 11.2682 5.05053 11.5286L9.53846 16.3905L18.9495 6.19526C19.1898 5.93491 19.5794 5.93491 19.8198 6.19526Z' fill='%23030D45'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat !important;
  content: "";
  height: 25px;
  width: 25px;
  top: 10px;
  right: 10px;
}

.multiselect__element:hover .multiselect__option--selected:after {
  color: #000 !important;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M19.8198 6.19526C20.0601 6.45561 20.0601 6.87772 19.8198 7.13807L9.9736 17.8047C9.73328 18.0651 9.34364 18.0651 9.10332 17.8047L4.18024 12.4714C3.93992 12.2111 3.93992 11.7889 4.18024 11.5286C4.42056 11.2682 4.8102 11.2682 5.05053 11.5286L9.53846 16.3905L18.9495 6.19526C19.1898 5.93491 19.5794 5.93491 19.8198 6.19526Z' fill='%23030D45'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
}
.multiselect__element .multiselect__option {
  padding: 15px 0px 15px 10px;
}
.multiselect__element .multiselect__option,
.multiselect__element .multiselect__option:after
{
  color: #000;
  background-color: #fff;
}
.multiselect * {
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-top: none;
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
.multiselect__element {
  border-top: 1px solid rgba(209, 213, 219);
  border-color: #eee;
}
.multiselect__input {
  position: absolute;
  left: 0px;
  height: 100%;
  width: 100%;
  top: 0px;
  padding: 14px;
}
</style>